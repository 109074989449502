// import store from "@/state/store";

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login.vue"),
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/account/register.vue"),
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("../views/account/forgot-password.vue"),
  },

  {
    path: "/",
    name: "default",
    meta: {
      title: "default",
      auth: true,
    },
    component: () => import("../modules/kanban/pages/main.vue"),
  },
  {
    path: "/reference",
    name: "reference",
    meta: {
      title: "Reference",
      auth: true,
    },
    component: () => import("../modules/reference/pages/main.vue"),
  },
  {
    path: "/reference/users",
    name: "users",
    meta: {
      title: "Users",
      auth: true,
    },
    component: () => import("../modules/reference/users/pages/main.vue"),
    children: [
      {
        path: "create",
        name: "userCreate",
        meta: {
          title: "Create",
          auth: true,
        },
        component: () => import("../modules/reference/users/pages/create.vue"),
      },
      {
        path: ":id",
        name: "userUpdate",
        meta: {
          title: "Update",
          auth: true,
        },
        component: () => import("../modules/reference/users/pages/update.vue"),
      },
    ],
  },
  {
    path: "/reference/activities",
    name: "activities",
    meta: {
      title: "Activities",
      auth: true,
    },
    component: () => import("../modules/reference/activities/pages/main.vue"),
    children: [
      {
        path: "create",
        name: "activitiesCreate",
        meta: {
          title: "Create",
          auth: true,
        },
        component: () => import("../modules/reference/activities/pages/create.vue"),
      },
      {
        path: ":id",
        name: "activitiesUpdate",
        meta: {
          title: "Update",
          auth: true,
        },
        component: () => import("../modules/reference/activities/pages/update.vue"),
      },
    ],
  },
  {
    path: "/reference/archive_reasons",
    name: "archive_reasons",
    meta: {
      title: "Archive_reasons",
      auth: true,
    },
    component: () => import("../modules/reference/leadArchiveReason/pages/main.vue"),
    children: [
      {
        path: "create",
        name: "archive_reasonsCreate",
        meta: {
          title: "Create",
          auth: true,
        },
        component: () => import("../modules/reference/leadArchiveReason/pages/create.vue"),
      },
      {
        path: ":id",
        name: "archive_reasonsUpdate",
        meta: {
          title: "Update",
          auth: true,
        },
        component: () => import("../modules/reference/leadArchiveReason/pages/update.vue"),
      },
    ],
  },
  {
    path: "/reference/service_types",
    name: "service_types",
    meta: {
      title: "Service_types",
      auth: true,
    },
    component: () => import("../modules/reference/serviceTypes/pages/main.vue"),
    children: [
      {
        path: "create",
        name: "service_typesCreate",
        meta: {
          title: "Create",
          auth: true,
        },
        component: () => import("../modules/reference/serviceTypes/pages/create.vue"),
      },
      {
        path: ":id",
        name: "service_typesUpdate",
        meta: {
          title: "Update",
          auth: true,
        },
        component: () => import("../modules/reference/serviceTypes/pages/update.vue"),
      },
    ],
  },
  {
    path: "/reference/contracts",
    name: "contracts",
    meta: {
      title: "Contracts",
      auth: true,
    },
    component: () => import("../modules/reference/contracts/pages/main.vue"),
  },
  {
    path: "/reference/contracts/create",
    name: "contractsCreate",
    meta: {
      title: "Create",
      auth: true,
    },
    component: () => import("../modules/reference/contracts/pages/create.vue"),
  },
  {
    path: "/reference/contracts/update/:id",
    name: "contractsUpdate",
    meta: {
      title: "update",
      auth: true,
    },
    component: () => import("../modules/reference/contracts/pages/update.vue"),
  },
  {
    path: "/reference/cargo-categories",
    name: "cargoCategories",
    meta: {
      title: "CargoCategories",
      auth: true,
    },
    component: () => import("../modules/reference/cargoCategories/pages/main.vue"),
    children: [
      {
        path: "create",
        name: "CargoCategoriesCreate",
        meta: {
          title: "Create",
          auth: true,
        },
        component: () => import("../modules/reference/cargoCategories/pages/create.vue"),
      },
      {
        path: ":id",
        name: "CargoCategoriesUpdate",
        meta: {
          title: "Update",
          auth: true,
        },
        component: () => import("../modules/reference/cargoCategories/pages/update.vue"),
      },
    ],
  },
  {
    path: "/reference/quote_jobs",
    name: "quote_jobs",
    meta: {
      title: "Quote_jobs",
      auth: true,
    },
    component: () => import("../modules/reference/quoteJobs/pages/main.vue"),
    children: [
      {
        path: "create",
        name: "quote_jobsCreate",
        meta: {
          title: "Create",
          auth: true,
        },
        component: () => import("../modules/reference/quoteJobs/pages/create.vue"),
      },
      {
        path: ":id",
        name: "quote_jobsUpdate",
        meta: {
          title: "Update",
          auth: true,
        },
        component: () => import("../modules/reference/quoteJobs/pages/update.vue"),
      },
    ],
  },
  {
    path: "/leads",
    name: "leads",
    meta: {
      title: "Leads",
      auth: true,
    },
    component: () => import("../modules/lead/pages/main.vue"),
  },
  {
    path: "/leads/create",
    name: "leadsCreate",
    meta: {
      title: "Create",
      auth: true,
    },
    component: () => import("../modules/lead/pages/create.vue"),
  },
  {
    path: "/leads/update/:id",
    name: "leadsUpdate",
    meta: {
      title: "Update",
      auth: true,
    },
    component: () => import("../modules/lead/pages/update.vue"),
  },
  {
    path: "/tasks",
    name: "tasks",
    meta: {
      title: "Tasks",
      auth: true,
    },
    component: () => import("../modules/tasks/pages/main.vue"),
    children: [
      {
        path: "create",
        name: "taskCreate",
        meta: {
          title: "Create",
          auth: true,
        },
        component: () => import("../modules/tasks/pages/create.vue"),
      },
      {
        path: "update/:id",
        name: "taskUpdate",
        meta: {
          title: "Update",
          auth: true,
        },
        component: () => import("../modules/tasks/pages/update.vue"),
      },
    ],
  },

  {
    path: "/ui",
    name: "ui",
    meta: {
      title: "ui",
      auth: true,
    },
    component: () => import("../views/ui/ui.vue"),
  },
];
